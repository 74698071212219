import { History, MessageCircleMore, MessageCircleOff, Mic, MicOff, UserIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/button";
import GroundingFileView from "@/components/ui/grounding-file-view";
import { GroundingFiles } from "@/components/ui/grounding-files";
import StatusMessage from "@/components/ui/status-message";

import {
    AudioDeltaMessage,
    AudioTranscriptionMessage,
    GroundingFile,
    HistoryItem,
    // AudioTranscriptDeltaMessage,
    Message,
    ToolResult
} from "@/types/types";

import useAudioPlayer from "@/hooks/useAudioPlayer";
import useAudioRecorder from "@/hooks/useAudioRecorder";
import useRealTime from "@/hooks/useRealtime";

import HistoryPanel from "@/components/ui/history-panel";
import logo from "@/assets/permagreen-logo-450x66.png";

// import { HoverBorderGradient } from "./components/ui/hover-border-gradient";
import { WavyBackground } from "@/components/ui/wavy-background";
// import { cn } from "@/lib/utils";
// import { motion, AnimatePresence } from "framer-motion";

function App() {
    const [isDesktopDevice] = useState(false);

    const [isChatting, setIsChatting] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isCalling, setIsCalling] = useState(false);

    const [groundingFiles, setGroundingFiles] = useState<GroundingFile[]>([]);
    const [selectedFile, setSelectedFile] = useState<GroundingFile | null>(null);

    const [showHistory, setShowHistory] = useState(false);
    const [chatHistory, setChatHistory] = useState<HistoryItem[]>([]);

    // const [synthesizer, setSynthesizer] = useState(null);

    const { startSession, addUserAudio, inputAudioBufferClear } = useRealTime({
        onWebSocketOpen: () => console.log("WebSocket connection opened"),
        onWebSocketClose: () => console.log("WebSocket connection closed"),
        onWebSocketError: (event: Event) => console.error("WebSocket error:", event),
        onReceivedError: (message: Message) => console.error("error", message),

        onReceivedInputAudioTranscriptionCompleted: (message: AudioTranscriptionMessage) => {
            console.log("User transcript received:", message);
            setChatHistory(prev => [
                ...prev,
                {
                    id: message.item_id,
                    transcript: `User: ${message.transcript}`,
                    groundingFiles: []
                }
            ]);
        },
        onReceivedResponseAudioDelta: (message: AudioDeltaMessage) => {
            isRecording && playAudio(message.delta);

            setChatHistory(prev => {
                const lastItem = prev[prev.length - 1];
                if (lastItem && !lastItem.transcript.startsWith("User:")) {
                    const content = message.text || message.delta;
                    return [
                        ...prev.slice(0, -1),
                        {
                            ...lastItem,
                            transcript: lastItem.transcript + content
                        }
                    ];
                } else {
                    return [
                        ...prev,
                        {
                            id: new Date().toISOString(),
                            transcript: `AI: ${message.text || message.delta}`,
                            groundingFiles: []
                        }
                    ];
                }
            });
        },

        onReceivedInputAudioBufferSpeechStarted: () => {
            stopAudioPlayer();
        },

        onReceivedExtensionMiddleTierToolResponse: message => {
            const result: ToolResult = JSON.parse(message.tool_result);

            const files: GroundingFile[] = result.sources.map((x: any) => {
                return { id: x.chunk_id, name: x.title, content: x.chunk };
            });

            setGroundingFiles(prev => [...prev, ...files]);

            setChatHistory(prev => {
                const lastItem = prev[prev.length - 1];
                if (lastItem && lastItem.transcript.startsWith("AI:")) {
                    return [
                        ...prev.slice(0, -1),
                        {
                            ...lastItem,
                            groundingFiles: [...lastItem.groundingFiles, ...files]
                        }
                    ];
                }
                return prev;
            });
        }
    });

    const { reset: resetAudioPlayer, play: playAudio, stop: stopAudioPlayer } = useAudioPlayer();
    const { start: startAudioRecording, stop: stopAudioRecording } = useAudioRecorder({ onAudioRecorded: addUserAudio });

    const onToggleListening = async () => {
        if (!isRecording) {
            startSession();
            await startAudioRecording();
            resetAudioPlayer();

            setIsRecording(true);
        } else {
            await stopAudioRecording();
            stopAudioPlayer();
            inputAudioBufferClear();

            setIsRecording(false);
        }
    };

    const onToggleChatting = async () => {
        if (!isChatting) {
            startSession();
            // await startAudioRecording();
            // resetAudioPlayer();

            setIsChatting(true);
        } else {
            // await stopAudioRecording();
            // stopAudioPlayer();
            // inputAudioBufferClear();

            setIsChatting(false);
        }
    };

    const onToggleCalling = async () => {
        if (!isCalling) {
            startSession();
            // await startAudioRecording();
            // resetAudioPlayer();

            setIsCalling(true);
        } else {
            // await stopAudioRecording();
            // stopAudioPlayer();
            // inputAudioBufferClear();

            setIsCalling(false);
        }
    };

    const handleHistoryToggle = () => {
        setShowHistory(prev => !prev);
    };

    const handleGroundingFileSelect = (file: GroundingFile) => {
        setSelectedFile(file);
        setShowHistory(false);
    };

    const { t } = useTranslation();

    return (
        <WavyBackground
            backgroundFill="white"
            blur={5}
            className="mx-auto max-w-4xl pb-40"
            colors={["#38bdf8", "#818cf8", "#c084fc", "#e879f9", "#22d3ee"]}
            enabled={isDesktopDevice}
            speed="slow"
            waveWidth={1}
            waveOpacity={0.5}
        >
            <div className="bg-white-900 flex min-h-screen flex-col text-gray-900">
                {/* <div className="bg-black-100 flex min-h-screen flex-col text-gray-900"> */}
                {/* <div className="flex min-h-screen flex-col bg-gray-100 text-gray-900"> */}
                {/* <div className="h-[50rem] w-full dark:bg-black bg-white dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center"> */}
                {/* Radial gradient for the container to give a faded look */}
                {/* <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div> */}
                <div className="flex justify-center p-4 sm:justify-start">
                    <img src={logo} alt="PermaGreen logo" className="h-[45px] w-auto" />
                </div>
                <main className="flex flex-grow flex-col items-center justify-center px-4">
                    <h3 className="bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-center text-xl font-bold text-transparent sm:text-2xl">
                        {t("app.preheader")}
                    </h3>
                    <h1 className="mb-8 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-center text-3xl font-bold text-transparent sm:text-4xl md:text-7xl">
                        {t("app.title")}
                    </h1>

                    <div className="flex w-full max-w-[800px] flex-col gap-2 px-4 sm:flex-row">
                        <Button
                            onClick={onToggleListening}
                            className={`h-12 flex-1 ${isRecording ? "bg-red-600 hover:bg-red-700" : "bg-purple-700 hover:bg-purple-900"}`}
                            aria-label={isRecording ? t("app.stopRecording") : t("app.startRecording")}
                        >
                            {isRecording ? (
                                <>
                                    <MicOff className="mr-2 h-4 w-4" />
                                    {t("app.stopConversation")}
                                </>
                            ) : (
                                <>
                                    <Mic className="mr-2 h-6 w-6" />
                                    {t("app.voiceMode")}
                                </>
                            )}
                        </Button>

                        <Button
                            onClick={onToggleChatting}
                            className={`h-12 flex-1 ${isChatting ? "bg-red-600 hover:bg-red-700" : "bg-blue-500 hover:bg-blue-600"}`}
                            aria-label={isChatting ? t("app.stopChat") : t("app.startChat")}
                        >
                            {isChatting ? (
                                <>
                                    <MessageCircleOff className="mr-2 h-4 w-4" />
                                    {t("app.stopConversation")}
                                </>
                            ) : (
                                <>
                                    <MessageCircleMore className="mr-2 h-6 w-6" />
                                    {t("app.textMode")}
                                </>
                            )}
                        </Button>

                        <Button
                            onClick={onToggleCalling}
                            className={`h-12 flex-1 ${isCalling ? "bg-red-600 hover:bg-red-700" : "bg-green-600 hover:bg-green-700"}`}
                            aria-label={isCalling ? t("app.stopCall") : t("app.startCall")}
                        >
                            {isCalling ? (
                                <>
                                    <UserIcon className="mr-2 h-4 w-4" />
                                    {t("app.stopCall")}
                                </>
                            ) : (
                                <>
                                    <UserIcon className="mr-2 h-6 w-6" />
                                    {t("app.callMode")}
                                </>
                            )}
                        </Button>
                    </div>

                    {/* <h3 className="mt-4 text-center text-sm font-bold text-white sm:text-base"> */}
                    {/* <h3 className="mt-4 bg-gradient-to-r from-yellow-100 to-yellow-300 bg-clip-text text-center text-md font-bold text-transparent sm:text-base"> */}
                    <h3 className="mt-4 bg-gradient-to-r from-green-600 to-blue-600 bg-clip-text text-center text-sm font-bold text-transparent sm:text-base">
                        {<StatusMessage isRecording={isRecording} />}
                    </h3>

                    <GroundingFiles files={groundingFiles} onSelected={setSelectedFile} />
                </main>

                <GroundingFileView groundingFile={selectedFile} onClosed={() => setSelectedFile(null)} />

                <Button
                    onClick={handleHistoryToggle}
                    className="fixed bottom-6 right-6 z-30 h-12 w-12 rounded-full bg-purple-700 shadow-lg hover:bg-purple-800"
                    aria-label="Toggle History"
                >
                    <History className="h-6 w-6" />
                </Button>

                <HistoryPanel
                    show={showHistory}
                    history={chatHistory}
                    onClosed={() => setShowHistory(false)}
                    onSelectedGroundingFile={handleGroundingFileSelect}
                />

                <footer className="px-4 py-4 text-center">
                    <h3 className="bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-sm font-bold text-transparent sm:text-base">
                        A.I. can potentially make mistakes. Please evaluate answers carefully, and{" "}
                        <a href="#" className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text font-bold text-transparent underline">
                            report any issues
                        </a>{" "}
                        to PermaGreen.
                    </h3>
                    <p className="text-sm">{t("app.footer")}</p>
                </footer>
            </div>
        </WavyBackground>
    );
}

export default App;
